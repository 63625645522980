import React from "react"
import styled, { css } from "styled-components"
import Brand from "./Brand"
import Button from "../../UI/Button"
import NavButton from "./NavButton"
import Nav from "./Nav"
import { Link } from "gatsby"

const Header = ({ className }) => {
	
	const [navOpen, setNavOpen] = React.useState(false)
		
	return (
		<header className={className}>
		  <NavButton onClick={() => setNavOpen(true)} />
		  <Link to="/" aria-label="Zenpharm"><Brand /></Link>
		  <div className="_spacer" />
		  <Nav isOpen={navOpen} onCloseButtonClick={() => setNavOpen(false)}/>
		  <Button to="/contact-us">Contact us</Button>
		</header>
  )
}

export default styled(Header)` ${({theme}) => css`
  	display: flex;
	align-items: center;
	padding: 4px;
	max-width: calc(1400px + 64px);
	margin: 0 auto;

	@media (min-width: ${theme.bp.sm}) {
		padding: 20px;
	}

	@media (min-width: ${theme.bp.md}) {
		padding: 32px;
	}

	@media (min-width: ${theme.bp.lg}) {
		padding: 32px;
	}

	${NavButton} {
		margin-right: 8px;

		@media (min-width: ${theme.bp.md}) {
			margin-right: 16px;
		}

		@media (min-width: ${theme.bp.lg}) {
			margin-left: 16px;
		}
	}

	._spacer {
		flex-grow: 1;
	}

	
	${Button} {

		@media (min-width: ${theme.bp.lg}) {
			font-size: 16px;
			padding: 16px 24px;
			margin-right: 0;
			margin-right: 16px;
		}

		@media (min-width: ${theme.bp.nav}) {
			margin-right: 0;
		}
	}


`}`