import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import Button from "../../UI/Button"
import Card from "../../UI/Card"

CtaBanner.propTypes = {
	className: PropTypes.string.isRequired,
}

function CtaBanner({ className }) {

	return (
		<Card accent className={className}>
			<p>Get in touch and let's see how we can help you</p>
			<Button to="/contact-us">Contact us</Button>
		</Card>
	)
}
export default styled(CtaBanner)`${({ theme })=>css`
	p {
		font-size: 20px;
		font-weight: 600;
		line-height: 1.6;
	}

	@media (min-width: ${theme.bp.md}) {
		display: flex;
		align-items: center;

		p {
			margin-bottom: 0;
			flex-grow: 1;
			font-size: 24px;
		}

		${Button} {
			margin-left: 48px;
		}
	}

	@media (min-width: ${theme.bp.lg}) {
		display: flex;
		align-items: center;

		p {
			margin-bottom: 0;
			flex-grow: 1;
			font-size: 32px;
		}

		${Button} {
			margin-left: 32px;
		}
	}

`}`