export default {
	bp: {
		sm: `360px`,
		md: `720px`,
		lg: `1200px`,
		nav: `1424px`,
		about: `640px`,
		contact: `1360px`,
	},
	
	c: {
		teal: `#01ADC4`,
		darkTeal: `#004B56`,
		yellow: `#F5F714`,

		text: `#006D7D`,
		focusTeal: `#008D9F`,
		clayText: `#252605`,
		tealText: `#0093A7`,
		tintTeal: `#E5F2F4`,
	}
}