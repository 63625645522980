import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

Card.propTypes = {
	className: PropTypes.string.isRequired,
}

function Card({ className, children, style }) {

	return (
		<div className={className} style={style}>
			{children}	
		</div>
	)
}
export default styled(Card)`${({ theme, accent })=>css`
	margin: 12px;
	padding: 12px;
	border-radius: 4px;
	max-width: 1400px;

	background: ${accent ? theme.c.teal : theme.c.tintTeal};
	color: ${accent ? `#fff` : theme.c.text};
	 

	@media (min-width: ${theme.bp.sm}) {
		margin: 16px;
		padding: 16px;
	}

	@media (min-width: ${theme.bp.md}) {
		margin: 32px;
		padding: 32px 24px;
	}

	@media (min-width: ${theme.bp.lg}) {
		margin: 48px;
		padding: 48px 64px;
	}
`}`