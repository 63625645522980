import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { Link } from "gatsby"

Button.propTypes = {
	className: PropTypes.string.isRequired,
}

function Button({ className, children, to }) {

	if (to) {
		return <Link to={to} className={className}>{children}</Link>
	}

	return (
		<button className={className}>
			{children}
		</button>
	)
}
export default styled(Button)`${({ theme })=>css`
	border-radius: 4px;
	background: ${theme.c.yellow};
	line-height: 1;
	padding: 16px 20px;
	color: ${theme.c.clayText};
	font-weight: 600;
	display: inline-block;
	font-size: 14px;
	user-select: none;
	white-space: nowrap;
	text-align: center;

	@media (min-width: ${theme.bp.md}) {
		font-size: 20px;
		padding: 20px 24px;	
	}
`}`