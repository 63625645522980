import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import Icon from "../../../UI/Icon"

Nav.propTypes = {
	className: PropTypes.string.isRequired,
}

function Nav({ className, onCloseButtonClick, isOpen }) {

	const [isResizing, setIsResizing] = React.useState(false)

	React.useEffect(() => {

		let timeout = null

		window.addEventListener("resize", () => {
			
			if (!isResizing) {
				setIsResizing(true)
				timeout = setTimeout(() => {
					setIsResizing(false)
				}, 200);
			}
		})
	})


	return (<>
		<nav className={className + ` ${!isResizing ? "has-transitions" : ""}`}>
			<button aria-label="Close" className="close" onClick={onCloseButtonClick}><Icon name="x" /></button>
			<Link tabIndex={isOpen ? "0" : "-1"} to="/services/qa-consultancy">QA Consultancy</Link>
			<Link tabIndex={isOpen ? "0" : "-1"} to="/services/qp-and-rp-services">QP & RP Services</Link>
			<Link tabIndex={isOpen ? "0" : "-1"} to="/services/auditing">Auditing</Link>
			<Link tabIndex={isOpen ? "0" : "-1"} to="/services/licenses-and-regulatory">Licenses & Regulatory</Link>
			<Link tabIndex={isOpen ? "0" : "-1"} to="/services/technical">Technical</Link>
			<Link tabIndex={isOpen ? "0" : "-1"} to="/services/training">Training</Link>
			<Link tabIndex={isOpen ? "0" : "-1"} to="/about-us">About us</Link>
		</nav>
		{isOpen && <div className="underlay" onClick={onCloseButtonClick} />}
		</>
	)
}
export default styled(Nav)`${({ theme, isOpen })=>css`
	@media (max-width: ${theme.bp.nav}) {
		position: fixed;
		z-index: 10;
		top: 0;
		bottom: 0;
		left: 0;
		transform: translate3d(${isOpen ? 0 : `-105%`}, 0, 0);
		&.has-transitions {transition: transform 0.12s ease-out;}
		box-shadow: 0px 4px 12px 0 rgba(0, 0, 0, 0.12);
		background: ${theme.c.tealText};
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		color: #fff;
		font-size: 18px;
		
		padding: 12px;
		@media (min-width: ${theme.bp.sm}) { padding: 16px; }
		@media (min-width: ${theme.bp.md}) { padding: 32px; }

		a {
			color: inherit;
			text-decoration: none;
			display: inline-block;
			width: 100%;
			padding: 12px;
			border-radius: 4px;
			border: 2px solid transparent;
			&:hover, &:focus {
				background: ${theme.c.focusTeal};
				outline: none;
			}
			&:focus {
				border-color: #fff;
			}
		}

		.close {	
			display: block;
			width: 40px;
			height: 40px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			
			${Icon} {
				width: 24px;
				height: 24px;
			}

			@media (min-width: ${theme.bp.md}) {
				
				width: 64px;
				height: 64px;

				${Icon} {
					height: 32px;
					width: 32px;
				}
			}
			color: inherit;
			background: none;
			text-decoration: none;
			border-radius: 4px;
			border: 2px solid transparent;
			line-height: 1;
			border-radius: 50%;
			cursor: pointer;
			&:hover, &:focus {
				background: ${theme.c.focusTeal};
				outline: none;
			}
			&:focus {
				border-color: #fff;
			}
		}

		+ .underlay {
			position: fixed;
			top: -24px;
			right: -24px;
			bottom: -24px;
			left: -24px;
			background: #000;
			z-index: 9;
			opacity: 0.5;

		}
	}

	@media (min-width: ${theme.bp.nav}) {
		.close {
			display: none;
		}

		.underlay {
			display: none;
		}

		a {
			font-size: 18px;
			color: ${theme.c.tealText};
			margin-right: 20px;
		}
	}
`}`