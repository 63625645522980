import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import Icon from "../../../UI/Icon"

NavButton.propTypes = {
	className: PropTypes.string.isRequired,
}

function NavButton({ className, onClick }) {

	return (
		<button className={className} onClick={onClick} aria-label="Menu">
			<Icon name="menu" />
		</button>
	)
}
export default styled(NavButton)`${({ theme })=>css`
	
	background: ${theme.c.tintTeal};
	border: none;
	color: ${theme.c.teal};
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	border: 2px solid transparent;
	cursor: pointer;

	&:hover, &:focus {
		background: ${theme.c.tintTeal};
	}

	&:focus {
		outline: none;
		border-color: ${theme.c.teal};
	}

	width: 40px;
	height: 40px;
	
	${Icon} {
		width: 32px;
		height: 32px;
	}

	@media (min-width: ${theme.bp.sm}) {
		
		width: 48px;
		height: 48px;

		${Icon} {
			height: 40px;
			width: 40px;
		}
	}
	
	
	@media (min-width: ${theme.bp.nav}) {
		display: none;
	}
`}`