import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import Icon from "../Icon"


LinkCard.propTypes = {
	className: PropTypes.string.isRequired,
}

function LinkCard({ className, to, children, icon }) {

	const internal = to[0] === "/"

	if (internal) return (
		<Link to={to} className={className} activeClassName="is-active">
			{icon && <Icon className="icon" name={icon} />}
			<span>{children}</span>
			{<Icon name="arrow-right" />}
		</Link>
	)

	if (!internal) return (
		<a href={to} className={className}>
			{icon && <Icon name={icon} className="icon" />}
			<span>{children}</span>
		</a>
	)
	
}
export default styled(LinkCard)`${({ theme, accent })=>css`
	display: inline-flex;
	align-items: center;
	line-height: 1;
	padding: 16px;
	background: #fff;
	border-radius: 4px;
	font-weight: 600;
	font-size: 14px;

	${accent && css`
		background: ${theme.c.yellow};
		color: ${theme.c.clayText};
	`}

	${Icon}.arrow-right {
		height: 24px;
		width: 24px;
	}

	${Icon}.icon {
		display: inline-block;
		flex-shrink: 0;
		margin-right: 16px;
		height: 20px;
		width: 20px;
	}

	span {
		flex-grow: 1;
	}

	@media (min-width: ${theme.bp.sm}) {
		font-size: 16px;
	}

	@media (min-width:${theme.bp.md}) {
		padding: 20px;
		font-size: 20px;
		font-size: 16px;
	}

	@media (min-width:${theme.bp.lg}) {
		padding: 32px;
		${'' /* font-size: 24px; */}
	}
`}`