import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

HeadingBlock.propTypes = {
	className: PropTypes.string.isRequired,
}

function HeadingBlock({ className, children }) {

	return (
		<div className={className}>
			{children}
		</div>
	)
}
export default styled(HeadingBlock)`${({ theme })=>css`
	margin: 12px;
	max-width: 1400px;
	color: ${theme.c.text};

	h1 {
		font-weight: 600;
	}

	p {
		font-size: 20px;
		font-weight: 600;
		margin-top: -8px;

		@media (min-width: ${theme.bp.md}) {
			font-size: 32px;
			margin-top: -16px;
		}

		@media (min-width: ${theme.bp.lg}) {
			margin-top: -24px;
		}
	}
	 
	@media (min-width: ${theme.bp.sm}) {
		margin: 16px;
		margin-top: 0;
	}

	@media (min-width: ${theme.bp.md}) {
		margin: 32px;
		margin-top: 0;
		
		.strap-line {
			font-size: 20px;
			margin-top: -1em;
			margin-bottom: 3em;
		}
	}

	@media (min-width: ${theme.bp.lg}) {
		margin: 48px;
		margin-top: 0;
	}
`}`