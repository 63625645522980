import { ThemeProvider } from "styled-components"
import React from "react"
import themeSettings from "./themeSettings"
import GlobalStyle from "./GlobalStyle"

function Theme({ children }) {

	return (
		<ThemeProvider theme={themeSettings}>
			<GlobalStyle />
			{children}
		</ThemeProvider>
	)
}
export default Theme