import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

Container.propTypes = {
	className: PropTypes.string.isRequired,
}

function Container({ className, children }) {

	return (
		<div className={className}>
			{children}
		</div>
	)
}
export default styled(Container)`${({ theme })=>css`
	max-width: calc(1400px + 96px);
	margin: 0 auto;

	min-height: calc(100vh - 264px);

`}`