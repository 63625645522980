import { createGlobalStyle, css } from "styled-components"

export const GlobalStyle = createGlobalStyle` ${({theme}) => css`
  @import url('https://fonts.googleapis.com/css?family=Poppins:400,600,700&display=swap');
    
  * {
    box-sizing: border-box;
  }
  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    touch-action: manipulation;
    over-flow: hidden;
  }
  body, h1, h2, h3, h4, h5, p {
    margin: 0;
  }
  body, input, textarea, button {
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
  }
  a {
		color: inherit;
		text-decoration: none;
		
		&:focus {
			outline: none;
		}
  }

  h1 {
	font-size: 28px;
	margin-bottom: 16px;

	@media (min-width: ${theme.bp.md}) {
		font-size: 48px;
		margin-bottom: 32px;
	}

	@media (min-width: ${theme.bp.lg}) {
		font-size: 56px;
		margin-bottom: 48px;
	}
  }

  h2 {
	  font-size: 24px;
	  margin-bottom: 16px;
	  margin-top: 32px;
	  font-weight: 600;
	  @media (min-width: ${theme.bp.md}) {
		font-size: 32px;
		margin-bottom: 32px;
		margin-top: 64px;
	  }
  }

  h3 {
	font-size: 16px;
	margin-bottom: 16px;
	font-weight: 600;
	@media (min-width: ${theme.bp.md}) {
		font-size: 20px;
	}
  }


  p {
	  font-size: 14px;
	  line-height: 1.8;
	  margin-bottom: 1em;

	  @media (min-width: ${theme.bp.sm}) {
		  font-size: 16px;
	  }

	  @media (min-width: ${theme.bp.md}) {
		  font-size: 20px;
	  }

  }
`}`
export default GlobalStyle