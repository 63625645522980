import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import ArrowRightIcon from "react-feather/dist/icons/arrow-right"
import PhoneIcon from "react-feather/dist/icons/phone"
import MailIcon from "react-feather/dist/icons/mail"
import LinkedInIcon from "react-feather/dist/icons/linkedin"
import MenuIcon from "react-feather/dist/icons/menu"
import XIcon from "react-feather/dist/icons/x"

Icon.propTypes = {
	className: PropTypes.string.isRequired,
}

function Icon({ className, name }) {

	switch (name) {
		case "arrow-right":
			return <ArrowRightIcon className={className} />
		
		case "phone":
			return <PhoneIcon className={className} />

		case "mail":
			return <MailIcon className={className} />

		case "linkedin":
			return <LinkedInIcon className={className} />
		
		case "menu":
			return <MenuIcon className={className} />
			
		case "x":
				return <XIcon className={className} />
		
		default: throw new Error(`Icon ${name} either hasn't been imported from react-feather or does not exist`)
	}
}
export default styled(Icon)`${({ theme })=>css`

`}`