import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"

const Footer = ({ className }) => (
  <footer className={className}>
		<div>&copy; {new Date().getFullYear()} Zenpharm</div>
		<div>company no: 8049976</div>
		<div>vat no: GB 133 6777 96</div>
		<Link to="/privacy">Privacy</Link>
  </footer>
)

export default styled(Footer)` ${({theme}) => css`

	max-width: calc(1400px + 64px);
	margin: 0 auto;
	font-size: 12px;
	color: ${theme.c.clayText};
	opacity: 0.75;
	padding: 0 8px 16px 8px;
	

	> div, > a {
		padding: 4px;
	}

	@media (min-width: ${theme.bp.sm}) {
		padding: 16px;
		padding-bottom: 32px;

		> div, > a {
			margin-right: 16px;
		}
	}

	@media (min-width: ${theme.bp.md}) {
		display: flex;
		justify-content: center;
		font-size: 16px;
		display: flex;
		justify-content: center;

		> div, > a {
			margin-right: 32px;
		}
	}

	._spacer {
		flex-grow: 1;
	}
`}`
